import React from 'react';
import { Grid } from '@mui/material';
import JobCard from './JobCard';

const JobList = ({ jobs, selectedJob, handleCardClick }) => {
  return (
      <Grid item xs={12} md={4} pr={1} sx={{ overflowY: 'auto', maxHeight: '100%'}}>
      {jobs.map((job) => (
        <JobCard
          key={job.job_link}
          job={job}
          onClick={() => handleCardClick(job)}
          selected={selectedJob && selectedJob.job_link === job.job_link}
        />
      ))}
    </Grid>
  );
};

export default JobList;
