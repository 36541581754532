import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import Quotations from './Quotations';

const LoadingSection = ({ progress, progressText }) => {
  return (
    <Box 
      px={2} 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',  // Center content vertically
        height: '100%', 
        textAlign: 'center',  // Center text horizontally
      }}
    >
      <Box width={"100%"} px={8}>
        <Quotations />
        <LinearProgress variant="determinate" value={progress} sx={{ marginBottom: 2, marginX: 25 }} />
        <Typography variant="body1">
          Status: {progressText}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingSection;
