import React, { useState, useEffect } from 'react';
import { Typography, Box, Fade } from '@mui/material';

const quotations = [
  {
    quote: "Patience is not simply the ability to wait - it's how we behave while we're waiting.",
    author: "A. P. J. Abdul Kalam"
  },
  {
    quote: "Patience, persistence, and perspiration make an unbeatable combination for success.",
    author: "Rahul Dravid"
  },
  {
    quote: "Patience and perseverance have a magical effect before which difficulties disappear and obstacles vanish.",
    author: "Lal Bahadur Shastri"
  },
  {
    quote: "Be faithful in small things because it is in them that your strength lies.",
    author: "Mother Teresa"
  },
  {
    quote: "Time is not measured by the passing of years but by what one does, what one feels, and what one achieves.",
    author: "Jawaharlal Nehru"
  },
  {
    quote: "Take the stones people throw at you and use them to build a monument.",
    author: "Ratan Tata"
  },
  {
    quote: "If you don't give up, you still have a chance. Giving up is the greatest failure.",
    author: "Dr. Devi Shetty"
  }
];

const Quotations = () => {
  const [index, setIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % quotations.length);
        setFadeIn(true);
      }, 1000); // Duration of the fade-out transition
    }, 5000); // Duration each quotation is displayed

    return () => clearInterval(interval);
  }, []);

  return (
    <Box textAlign="center" mb={16}>
      <Fade in={fadeIn} timeout={1000}>
        <Box>
           <Typography variant="h3" sx={{ fontStyle: 'italic', lineHeight: 1.33}}>
            "{quotations[index].quote}"
          </Typography>
          <Typography variant="h5" mt={3}>
            - {quotations[index].author}
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
};

export default Quotations;
