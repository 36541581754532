import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchUserJobAnalyses } from '../../services/api';
import useAuth from '../../hooks/useAuth';
import { initialFilters, FilterSection } from './FilterSection';
import JobTable from './JobTable';

const PreviousSearches = () => {
  const { auth } = useAuth();
  const [jobAnalyses, setJobAnalyses] = useState([]);
  const [filteredAnalyses, setFilteredAnalyses] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAnalyses = async () => {
      try {
        const data = await fetchUserJobAnalyses(auth);
        setJobAnalyses(data);
        setFilteredAnalyses(data);
      } catch (error) {
        setError('Failed to fetch previous searches');
      }
    };

    fetchAnalyses();
  }, [auth]);

  useEffect(() => {
    const { keyword, location, status, dateRange } = filters;
    const isStatusSelected = (analysisStatus) => {
      if (status.includes('All')) {
        return true;
      }
      if (status.includes('Completed') && analysisStatus === 'completed') {
        return true;
      }
      if (status.includes('Failed') && analysisStatus === 'failed') {
        return true;
      }
      if (status.includes('Pending') && analysisStatus === 'pending') {
        return true;
      }
      if (status.includes('In Progress') && ['analyzing', 'scraping', 'filtering'].includes(analysisStatus)) {
        return true;
      }
      return false;
    };

    setFilteredAnalyses(
      jobAnalyses.filter((analysis) => {
        const keywordMatch = analysis.originalQuery.keyword.toLowerCase().includes(keyword.toLowerCase());
        const locationMatch = analysis.originalQuery.location?.toLowerCase().includes(location.toLowerCase());
        const statusMatch = isStatusSelected(analysis.status);
        const startDate = dateRange[0] ? new Date(dateRange[0]) : null;
        const endDate = dateRange[1] ? new Date(dateRange[1]) : null;
        const dateMatch =
          (!startDate || new Date(analysis.createdAt) >= startDate) &&
          (!endDate || new Date(analysis.createdAt) <= endDate);

        return keywordMatch && locationMatch && statusMatch && dateMatch;
      })
    );
  }, [filters, jobAnalyses]);

  const handleViewResults = (id) => {
    navigate(`/results/${id}`);
  };

  const rows = filteredAnalyses.map((analysis) => ({
    id: analysis._id,
    keyword: analysis.originalQuery.keyword,
    createdAt: analysis.createdAt,
    status: analysis.status,
    location: analysis.originalQuery.location || 'N/A',
    dateSincePosted: analysis.originalQuery.dateSincePosted || 'N/A',
    jobType: analysis.originalQuery.jobType?.join(', ') || 'N/A',
    experienceLevel: analysis.originalQuery.experienceLevel?.join(', ') || 'N/A',
  }));

  return (
    <Container component="main" maxWidth="xl">
      <Paper elevation={8} sx={{ backgroundColor: "rgba(255, 255, 255, 0.97)", padding: 3, width: '100%', height: 'calc(95vh - 64px)', overflow: 'hidden' }}>
        <Typography variant="h5">Previous Searches</Typography>
        {error && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
        <FilterSection filters={filters} setFilters={setFilters} />
        {jobAnalyses.length === 0 ? (
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            No previous searches found.
          </Typography>
        ) : (
          <JobTable rows={rows} handleViewResults={handleViewResults} />
        )}
      </Paper>
    </Container>
  );
};

export default PreviousSearches;
