import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchProfile, startJobAnalysis } from '../../services/api';
import useAuth from '../../hooks/useAuth';
import ResumeUpload from './ResumeUpload';
import FormField from './FormField';
import CenteredContainer from '../Common/CenteredContainer';
import WaizLogo from '../Common/WaizLogo';

const jobTypes = ['Full Time', 'Part Time', 'Contract', 'Temporary', 'Volunteer', 'Internship'];
const experienceLevels = ['Internship', 'Entry Level', 'Associate', 'Senior', 'Director', 'Executive'];
const datePostedOptions = ['Past 24 hours', 'Past Week', 'Past Month'];

const SearchJobs = () => {
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('');
  const [dateSincePosted, setDateSincePosted] = useState('Past Week');
  const [jobType, setJobType] = useState(['Full Time']);
  const [experienceLevel, setExperienceLevel] = useState(['Entry Level', 'Associate', 'Senior']);
  const [limit, setLimit] = useState(50);
  const [profile, setProfile] = useState(null);
  const [useExistingResume, setUseExistingResume] = useState(true);
  const [resumeName, setResumeName] = useState('');  // Define resumeName
  const navigate = useNavigate();
  const { auth } = useAuth();

  useEffect(() => {
    const getProfile = async () => {
      try {
        const data = await fetchProfile(auth);
        setProfile(data);
        setUseExistingResume(!!data.resume); // Auto-select existing resume if it exists
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    getProfile();
  }, [auth]);

  const submitJobAnalysis = async () => {
    const selectedExperienceLevel = experienceLevel.length === experienceLevels.length ? [] : experienceLevel;

    const formData = {
      keyword,
      location,
      dateSincePosted,
      jobType,
      experienceLevel: selectedExperienceLevel,
      limit
    };

    try {
      const response = await startJobAnalysis(formData, auth);
      const jobAnalysisId = response.jobAnalysisId;
      navigate(`/results/${jobAnalysisId}`);
    } catch (error) {
      console.error('Error starting job analysis:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await submitJobAnalysis();
  };

  const handleJobTypeChange = (event) => {
    const { target: { value } } = event;
    setJobType(typeof value === 'string' ? value.split(',') : value);
  };

  const handleExperienceLevelChange = (event) => {
    const { target: { value } } = event;
    setExperienceLevel(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <CenteredContainer component="main" maxWidth="xl">
        <Grid container spacing={{sm:8, md:0}}>
          <Grid item sm={12} md={8} sx={{display: {xs: 'none', sm: 'block'}}}>
            <>
              <Box display="flex" justifyContent={{sm: 'center', md: 'left'}} mt={{sm:4, md:0}} mr={{sm:0, md: 16}} mb={{sm:2, md: 8}}>
                  <WaizLogo/>
              </Box>
              <Typography variant='h2' color="secondary" textAlign={{sm: 'center', md:'left'}}
                sx={{textShadow: "2px 2px 4px black"}} 
                mr={{sm:0, md: 16}} lineHeight={1.33}>
                We believe searching for a job, should not be a job.
              </Typography>
            </>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={8} sx={{padding: {xs:2, md: 3}}}>
              <Typography variant="h5">Your next job requirements</Typography>
              <form onSubmit={handleSubmit} >
                <Grid container spacing={{sm: 2}}>
                  <Grid item xs={12} sm={7}>
                    <FormField id="keyword" label="Job Title" value={keyword} onChange={(e) => setKeyword(e.target.value)} required={true} placeholder="Product Manager" />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormField id="location" label="Location" value={location} onChange={(e) => setLocation(e.target.value)} required={true} placeholder="Bengaluru" />
                  </Grid>
                </Grid>
                <FormField id="experienceLevel" label="Experience Level" value={experienceLevel} onChange={handleExperienceLevelChange} options={experienceLevels} multiple={true} />
                <FormField id="jobType" label="Job Type" value={jobType} onChange={handleJobTypeChange} options={jobTypes} multiple={true} />
                <Grid container spacing={2}>
                  <Grid item xs={8} sm={7}>
                    <FormField id="dateSincePosted" label="Date Posted" value={dateSincePosted} onChange={(e) => setDateSincePosted(e.target.value)} options={datePostedOptions} required={true} />
                  </Grid>
                  <Grid item xs={4} sm={5}>
                    <FormField id="limit" label="Number of Results" value={limit} onChange={(e) => setLimit(e.target.value)} type="number" required={true} />
                  </Grid>
                </Grid>
                <ResumeUpload
                  profile={profile}
                  useExistingResume={useExistingResume}
                  setUseExistingResume={setUseExistingResume}
                  handleResumeChange={(e) => setResumeName(e.target.files[0].name)}
                  resumeName={resumeName}  // Pass resumeName as a prop
                />
                <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: 20 }}>
                  Ask AI to Find Jobs
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </CenteredContainer>
    </>
  );
};

export default SearchJobs;
