import React from 'react';
import { TextField, MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText, OutlinedInput } from '@mui/material';

const FormField = ({ id, label, value, onChange, options = [], multiple = false, required = false, placeholder = '' }) => {
  if (options.length > 0) {
    return (
      <FormControl variant="outlined" margin="normal" fullWidth required={required}>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value}
          onChange={onChange}
          label={label}
          multiple={multiple}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (multiple ? selected.join(', ') : selected)}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {multiple && <Checkbox checked={value.indexOf(option) > -1} />}
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <TextField
      variant="outlined"
      margin="normal"
      required={required}
      fullWidth
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default FormField;
