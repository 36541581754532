import { useState } from 'react';

const useAuth = () => {
  const [auth, setAuth] = useState(localStorage.getItem('token') || '');

  const logout = () => {
    localStorage.removeItem('token');
    setAuth('');
  };

  return { auth, setAuth, logout };
};

export default useAuth;
