import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip } from '@mui/material';
import { styled } from '@mui/system';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  }
}));


const statusColors = {
  completed: 'success',
  failed: 'error',
  pending: 'info',
  analyzing: 'info',
  scraping: 'info',
  filtering: 'info',
};

const JobTable = ({ rows, handleViewResults }) => {
  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      width: 150,
      align: 'center',
      renderCell: (params) => (
        <Chip label={params.value} color={statusColors[params.value]} sx={{textTransform: 'capitalize'}} />
      ),
    },
      { field: 'createdAt', headerName: 'Date', width: 150, type: 'date', valueGetter: (value) => new Date(value), valueFormatter: (value) => new Date(value).toLocaleDateString(undefined, {
          dateStyle: 'medium'
    }) },
    { field: 'keyword', headerName: 'Keyword', width: 250 },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'dateSincePosted', headerName: 'Date Posted', width: 150 },
    { field: 'jobType', headerName: 'Job Type', width: 250, sortable: false },
    { field: 'experienceLevel', headerName: 'Experience Level', width: 250, sortable: false},
  ];

  return (
    <Box sx={{ height: 'calc(100% - 64px)', width: '100%' }}>
      <StyledDataGrid
        initialState={{
            sorting: {
              sortModel: [{ field: 'createdAt', sort: 'desc' }],
            },
        }}
        disableColumnMenu
        disableMultipleRowSelection
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
        disableSelectionOnClick
        getRowId={(row) => row.id}
        onRowClick={(params) => handleViewResults(params.id)}
        sx={{ width: '100%' }}
      />
    </Box>
  );
};

export default JobTable;
