import React, { useState, useEffect } from 'react';
import { Box, Typography, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import JobCard from './JobCard';
import JobDetails from './JobDetails';

const JobListMobile = ({ jobs, selectedJob, handleCardClick, bottomSheetOpen, handleCloseBottomSheet }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (!bottomSheetOpen) {
      setIsExpanded(false);
    }
  }, [bottomSheetOpen]);

  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    if (scrollTop > 50 && !isExpanded) {
      setIsExpanded(true);
    } else if (scrollTop <= 10 && isExpanded) {
      setIsExpanded(false);
    }
  };

  return (
    <>
      <Typography variant="h5" align='center' mb={2} fontWeight="bold">Job Listings</Typography>
      <Box sx={{ overflowY: 'auto', maxHeight: 'calc(100vh - 56px)' }}>
        {jobs.map((job) => (
          <JobCard
            key={job.job_link}
            job={job}
            onClick={() => handleCardClick(job)}
            selected={selectedJob && selectedJob.job_link === job.job_link}
          />
        ))}
      </Box>
      <Dialog
        open={bottomSheetOpen}
        onClose={handleCloseBottomSheet}
        maxWidth="sm"
        sx={{
          top: 'auto',
          bottom: 0,
          height: isExpanded ? '100%' : '75%',
          margin: 0,
          overflow: 'hidden',
          transition: 'height 0.3s ease',
          '& .MuiDialog-paper': {
            margin: 0,
            borderRadius: '12px 12px 0 0',
            height: '100%',
            maxHeight: '100%',
          },
        }}
      >
        <Box p={2} sx={{ position: 'relative', height: '100%' }}>
          <IconButton
            onClick={handleCloseBottomSheet}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              backgroundColor: 'white',
              boxShadow: 3,
              '&:hover': {
                backgroundColor: 'grey.200',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ overflowY: 'auto', paddingTop: 4, height: '100%' }} onScroll={handleScroll}>
            {selectedJob ? (
              <JobDetails job={selectedJob} />
            ) : (
              <Typography variant="h6">Select a job to see details</Typography>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default JobListMobile;
