import { useState, useEffect } from 'react';
import { createJobAnalysisWebSocket } from '../services/api';
import useAuth from './useAuth';
import { getOverallProgress } from '../components/JobResults/progressUtil.js';

const useJobAnalysisWebSocket = (id) => {
  const { auth } = useAuth();
  const [jobs, setJobs] = useState([]);
  const [status, setStatus] = useState('loading');
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState('Scraping 0%');
  const [error, setError] = useState(null);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    const connectWebSocket = async () => {
      try {
        const ws = createJobAnalysisWebSocket(auth);

        ws.onopen = () => {
          // console.log('WebSocket connection opened');
          ws.send(JSON.stringify({ jobId: id }));
        };

        ws.onmessage = (event) => {
        //   console.log('WebSocket message received:', event.data);
          const data = JSON.parse(event.data);
          setStatus(data.status);
          setQuery(data.originalQuery)

          if (data.status === 'scraping' || data.status === 'filtering' || data.status ===  'analyzing') {
            const [stage, stageProgress] = data.progress.split(' ');
            const progressPercentageText = stageProgress.slice(0, -1); // Remove the trailing colon
            const progressPercentage = parseInt(progressPercentageText);
            // console.log(stage, progressPercentage)
            setProgress(getOverallProgress(stage, progressPercentage));
          }

          setProgressText(data.progress);

          if (data.status === 'completed') {
            setJobs(data.results);
            ws.close();
          } else if (data.status === 'failed') {
            setError('Job analysis failed. Please retry.');
            ws.close();
          }
        };

        ws.onerror = (err) => {
          console.error('WebSocket failed:', err);
          setError('Failed to fetch status. Retrying...');
          ws.close();
        };

        ws.onclose = () => {
          console.log('WebSocket connection closed');
        };

        return () => {
          ws.close();
        };
      } catch (err) {
        setError('Failed to fetch WebSocket token. Please retry.');
      }
    };

    connectWebSocket();
  }, [id, auth]);

  return { jobs, status, progress, progressText, error, setStatus, setProgress, setJobs, query };
};

export default useJobAnalysisWebSocket;
