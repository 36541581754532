
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Auth from './components/Auth/Auth';
import PreviousSearches from './components/PreviousSearches/PreviousSearches';
import SearchJobs from './components/JobSearch/SearchJobs';
import JobResults from './components/JobResults/JobResults';
import { AppBar, Container, Toolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';
import backgroundImage from './assets/images/bg_black_crumpled.jpg'; // https://unsplash.com/photos/a-black-crumpled-paper-background-with-a-black-background-Ya-IIca3PjM?utm_content=creditShareLink&utm_medium=referral&utm_source=unsplash

const BackgroundContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  marginTop: '-64px',
  paddingTop: '64px',
  backgroundImage: `url(${backgroundImage})`, // Replace with your background image URL
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const CustomAppBar = styled(AppBar)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
});

const CustomTypography = styled(Typography)({
  flexGrow: 1,
  color: 'white',
  fontWeight: 'bold',
});

const CustomButton = styled(Button)({
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent white background on hover
  },
});

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <CustomAppBar position="static">
            <Container maxWidth="xl">
              <Toolbar disableGutters={true}>
                <CustomTypography variant="h6">
                  waizjobs
                </CustomTypography>
                <CustomButton component={Link} to="/searchjobs">
                  Search Jobs
                </CustomButton>
                <AuthContext.Consumer>
                  {({ auth, logout }) =>
                    auth ? (
                      <>
                        <CustomButton component={Link} to="/previous-searches">
                          History
                        </CustomButton>
                        <CustomButton onClick={logout}>
                          Logout
                        </CustomButton>
                      </>
                    ) : (
                      <CustomButton component={Link} to="/auth">
                        Sign In / Register
                      </CustomButton>
                    )
                  }
                </AuthContext.Consumer>
              </Toolbar>
            </Container>
          </CustomAppBar>
          <BackgroundContainer>
            <Routes>
              <Route path="/auth" element={<Auth />} />
              <Route path="/previous-searches" element={<PreviousSearches />} />
              <Route path="/searchjobs" element={<SearchJobs />} />
              <Route path="/results/:id" element={<JobResults />} />
              <Route path="/" element={<SearchJobs />} />
            </Routes>
          </BackgroundContainer>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
