import React from 'react';
import { TextField, Box } from '@mui/material';

const DatePicker = ({ searchDateRange, setSearchDateRange }) => {
  const handleStartDateChange = (e) => {
    const newDateRange = [e.target.value, searchDateRange[1]];
    setSearchDateRange(newDateRange);
  };

  const handleEndDateChange = (e) => {
    const newDateRange = [searchDateRange[0], e.target.value];
    setSearchDateRange(newDateRange);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        type="date"
        label="Start Date"
        value={searchDateRange[0] || ''}
        onChange={handleStartDateChange}
        fullWidth
        InputLabelProps={{ shrink: true }}
        placeholder="YYYY-MM-DD"
      />
      <Box sx={{ mx: 2 }}>to</Box>
      <TextField
        type="date"
        label="End Date"
        value={searchDateRange[1] || ''}
        onChange={handleEndDateChange}
        fullWidth
        InputLabelProps={{ shrink: true }}
        placeholder="YYYY-MM-DD"
      />
    </Box>
  );
};

export default DatePicker;
