import axios from 'axios';

// Function to get API base URL and WebSocket URL in production
const getUrls = async () => {
  if (process.env.NODE_ENV === 'production') {
    const response = await fetch('/.netlify/functions/getApiBaseUrl');
    const data = await response.json();
    return {
      apiBaseUrl: data.apiBaseUrl.concat('/api'),
      wsBaseUrl: data.apiBaseUrl.replace(/^http/, 'ws')
    };
  }
  return {
    apiBaseUrl: 'http://localhost:5050/api',
    wsBaseUrl: 'ws://localhost:5050'
  };
};

// Initialize axios instance and WebSocket URL
let axiosInstance;
let wsBaseUrl;

const initializeUrls = async () => {
  const { apiBaseUrl, wsBaseUrl: wsUrl } = await getUrls();

  axiosInstance = axios.create({
    baseURL: apiBaseUrl,
  });

  wsBaseUrl = wsUrl;
};

// Helper function to handle errors
const handleError = (error) => {
  if (error.response && error.response.data) {
    throw error.response.data;
  } else {
    throw new Error('An unknown error occurred');
  }
};

// Helper function to get headers with token
const getAuthHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// Ensure axios instance is initialized before each API call
const ensureInitialized = async () => {
  if (!axiosInstance || !wsBaseUrl) {
    await initializeUrls();
  }
};

// Register user
const registerUser = async (data) => {
  await ensureInitialized();
  try {
    const response = await axiosInstance.post('/auth/register', data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Login user
const loginUser = async (data) => {
  await ensureInitialized();
  try {
    const response = await axiosInstance.post('/auth/login', data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Fetch user profile
const fetchProfile = async (token) => {
  await ensureInitialized();
  try {
    const response = await axiosInstance.get('/user/profile', getAuthHeaders(token));
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Upload resume
const uploadResume = async (file, token) => {
  await ensureInitialized();
  const formData = new FormData();
  formData.append('resume', file);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axiosInstance.post('/user/uploadResume', formData, config);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Update user profile
const updateProfile = async (data, token) => {
  await ensureInitialized();
  try {
    const response = await axiosInstance.put('/user/profile', data, getAuthHeaders(token));
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Fetch user job analyses
const fetchUserJobAnalyses = async (token) => {
  await ensureInitialized();
  try {
    const response = await axiosInstance.get('/jobs/analyses', getAuthHeaders(token));
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Start job analysis
const startJobAnalysis = async (data, token) => {
  await ensureInitialized();
  try {
    const response = await axiosInstance.post('/jobs/analyze', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Create WebSocket for job analysis
const createJobAnalysisWebSocket = (token) => {
  const ws = new WebSocket(`${wsBaseUrl}`, token); // Pass token as subprotocol
  return ws;
};

// Fetch specific job analysis
const fetchJobAnalysis = async (id, token) => {
  await ensureInitialized();
  try {
    const response = await axiosInstance.get(`/jobs/analyze/${id}`, getAuthHeaders(token));
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export {
  registerUser,
  loginUser,
  fetchProfile,
  updateProfile,
  uploadResume,
  fetchUserJobAnalyses,
  startJobAnalysis,
  createJobAnalysisWebSocket,
  fetchJobAnalysis,
};

