import React, { useState } from 'react';
import { Dialog, Typography, DialogContent, DialogTitle, IconButton, TextField, Box, Button } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateProfile } from '../../services/api';
import useAuth from '../../hooks/useAuth';

const ResumeModal = ({ open, handleClose, resumeContent }) => {
  const [editMode, setEditMode] = useState(false);
  const [markdownContent, setMarkdownContent] = useState(resumeContent);
  const { auth } = useAuth();

  const handleEditToggle = () => {
    setEditMode(!editMode);
  };

  const handleSave = async () => {
    try {
      // Call API to update the profile with the new markdown
      await updateProfile({ resume: markdownContent }, auth);
      setEditMode(false);
      setMarkdownContent(markdownContent); // Update the displayed content
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h5">Existing Resume</Typography>
        <Box sx={{ position: 'absolute', right: 8, top: 8, display: 'flex', alignItems: 'center' }}>
          {editMode ? (
            <>
              <Button onClick={handleSave} color="primary" endIcon={<SaveIcon />} sx={{ marginRight: 1 }}>
                Save
              </Button>
              <Button onClick={handleEditToggle} color="inherit" endIcon={<CancelIcon />} sx={{ marginRight: 1 }}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleEditToggle} variant="outlined" color="primary" endIcon={<EditIcon />} sx={{ marginRight: 1 }}>
                Edit
              </Button>
              <IconButton onClick={handleClose} color="inherit">
                <CloseIcon />
              </IconButton>
            </>
          )}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: '600px', display: 'flex', flexDirection: 'column' }}>
        {editMode ? (
          <TextField
            multiline
            sx={{ flex: 1 }}
            variant="outlined"
            fullWidth
            value={markdownContent}
            onChange={(e) => setMarkdownContent(e.target.value)}
          />
        ) : (
          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownContent}</ReactMarkdown>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ResumeModal;
