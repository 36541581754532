import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#212121',
    },
    secondary: {
      main: '#e0e0e0',
    },
    background: {
      default: 'rgba(0, 0, 0, 0.5)',
    },
    text: {
      primary: '#050F06',
      secondary: '#777',
    },
    success: {
      main: '#cfe8d0',
    },
    error: {
      main: '#fbdcda',
    },
    info: {
      main: '#bcdef8',
    },
    warning: {
      main: '#ff9800',
    },
  },
  typography: {
    fontSize: 14,
  },
});

theme = responsiveFontSizes(theme);

export default theme;
