import React, { useState } from 'react';
import { Box, Typography, Button, Chip, IconButton, Popover } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const JobDetails = ({ job }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const renderChips = (skills) => {
    if (!skills) return 'N/A';
    return skills.split(',').map((skill, index) => (
      <Chip key={index} label={skill.trim()} sx={{ margin: '2px' }} />
    ));
  };

  return (
    <Box>
      <Typography variant="h5">{job.position}</Typography>
      <Typography variant="subtitle1">{job.company}</Typography>
      <Box mt={2}>
        <Typography variant="body1"><strong>Team:</strong> {job.team || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Field:</strong> {job.field || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Date Posted:</strong> {job.date_posted || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Field Experience Needed:</strong> {job.field_experience_needed || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Overall Experience Needed:</strong> {job.overall_experience_needed || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Candidate's Overall Experience:</strong> {job.candidates_overall_experience || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Candidate's Field Experience:</strong> {job.candidates_field_experience || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Experience Gap:</strong> {job.experience_gap || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Field Experience Gap:</strong> {job.field_experience_gap || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Resume to JD Match:</strong> {job.resume2jd_match || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Skill Mandatory Match:</strong> {job.skill_mandatory_match || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Skill Preferred Match:</strong> {job.skill_prefered_match || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Overall Exp Score:</strong> {job.overall_exp_score || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Field Exp Score:</strong> {job.field_exp_score || 'N/A'}</Typography>
        <Typography variant="body1"><strong>Match:</strong> {job.match || 'N/A'}
          <IconButton
            size="small"
            onClick={handlePopoverOpen}
            sx={{ ml: 1 }}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </Typography>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box p={2} sx={{ maxWidth: '300px' }}>
            <Typography variant="h6">Relevance Score Breakdown</Typography>
            <Typography variant="body2"><strong>Keywords Match:</strong> {job.keywords_match || 'N/A'}</Typography>
            <Typography variant="body2"><strong>Skills Match:</strong> {job.skills_match || 'N/A'}</Typography>
            <Typography variant="body2"><strong>Experience Match:</strong> {job.experience_match || 'N/A'}</Typography>
            <Typography variant="body2"><strong>Location Match:</strong> {job.location_match || 'N/A'}</Typography>
            <Typography variant="body2"><strong>Education Match:</strong> {job.education_match || 'N/A'}</Typography>
            <Typography variant="body2" color="error"><strong>Negative Factors:</strong></Typography>
            <Typography variant="body2" color="error">{job.negative_factors || 'N/A'}</Typography>
          </Box>
        </Popover>
        <Typography variant="body1" mt={2}><strong>Required Skills:</strong></Typography>
        <Box mt={1}>
          {renderChips(job.required_skills)}
        </Box>
        <Typography variant="body1" mt={2}><strong>Preferred Skills:</strong></Typography>
        <Box mt={1}>
          {renderChips(job.preferred_skills)}
        </Box>
        <Typography variant="body1" mt={2}><strong>Missing Skills:</strong></Typography>
        <Box mt={1}>
          {renderChips(job.missing_skills)}
        </Box>
        <Button
          variant="contained"
          color="primary"
          href={job.job_link}
          target="_blank"
          sx={{ marginTop: 2 }}
        >
          Apply Now
        </Button>
        <Typography variant="body1" mt={2}><strong>Job Description:</strong></Typography>
        <Box mt={2}>
          <ReactMarkdown>{job.jd_markdown || 'N/A'}</ReactMarkdown>
        </Box>
      </Box>
    </Box>
  );
};

export default JobDetails;
