import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Paper, Grid, CircularProgress, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useJobAnalysisWebSocket from '../../hooks/useJobAnalysisWebSocket';
import CenteredContainer from '../Common/CenteredContainer';
import JobDetails from './JobDetails';
import JobList from './JobList';
import JobListMobile from './JobListMobile';
import LoadingSection from './LoadingSection';
import RetrySection from './RetrySection';
import { startJobAnalysis } from '../../services/api';
import useAuth from '../../hooks/useAuth';

const JobResults = () => {
  const theme = useTheme();
  const isMdOrLarger = useMediaQuery(theme.breakpoints.up('md'));
  const { id } = useParams();
  const { jobs, status, progress, progressText, error, query } = useJobAnalysisWebSocket(id);
  const [selectedJob, setSelectedJob] = useState(null);
  const [bottomSheetOpen, setBottomSheetOpen] = useState(false);
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'completed' && jobs.length > 0 && isMdOrLarger) {
      setSelectedJob(jobs[0]);
    }
  }, [status, jobs, isMdOrLarger]);

  const handleRetry = async () => {
    if (query) {
      const newJobAnalysis = await startJobAnalysis(query, auth);
      navigate(`/results/${newJobAnalysis.jobAnalysisId}`);
    } else {
      console.log("Didn't get Original query");
    }
  };

  const handleCardClick = (job) => {
    setSelectedJob(job);
    if (!isMdOrLarger) {
      setBottomSheetOpen(true);
    }
  };

  const handleCloseBottomSheet = () => {
    setBottomSheetOpen(false);
  };

  return (
      <CenteredContainer component="main" maxWidth="xl" disableGutters={isMdOrLarger ? false : true}>
          <Paper elevation={8} sx={{ backgroundColor: "rgba(255, 255, 255, 0.97)", padding: {xs: 2 , md:3}, width: '100%', height: {xs: 'calc(100vh - 56px)', md: 'calc(95vh - 64px)'}, overflow: 'hidden' }}>
        {status === 'completed' ? (
          isMdOrLarger ? (
            <Grid container sx={{ height: '100%' }}>
                <JobList jobs={jobs} selectedJob={selectedJob} handleCardClick={handleCardClick} />
                <Grid item xs={12} px={2} md={8} sx={{ overflowY: 'auto', maxHeight: '100%' }}>
                {selectedJob ? (
                  <JobDetails job={selectedJob} />
                ) : (
                  <Typography variant="h6">Select a job to see details</Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <JobListMobile
              jobs={jobs}
              selectedJob={selectedJob}
              handleCardClick={handleCardClick}
              bottomSheetOpen={bottomSheetOpen}
              handleCloseBottomSheet={handleCloseBottomSheet}
            />
          )
        ) : (
          status === 'failed' ? (
            <RetrySection error={error} handleRetry={handleRetry} />
          ) : (
            status === 'loading' ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <LoadingSection progress={progress} progressText={progressText} />
            )
          )
        )}
      </Paper>
    </CenteredContainer>
  );
};

export default JobResults;
