import React, { useState } from 'react';
import { Button, Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress } from '@mui/material';
import ResumeModal from '../Common/ResumeModal';
import ConfirmDialog from '../Common/ConfirmDialog';
import { uploadResume } from '../../services/api';
import useAuth from '../../hooks/useAuth';

const ResumeUpload = ({ profile, useExistingResume, setUseExistingResume, handleResumeChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [newResumeName, setNewResumeName] = useState('');
  const [error, setError] = useState(null);
  const { auth } = useAuth();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const onRadioChange = (event) => setUseExistingResume(event.target.value === 'existing');

  const onFileChange = (e) => {
    const file = e.target.files[0];
    setNewResumeName(file.name);
    if (file) {
      setConfirmOpen(true);
    }
  };

  const confirmUpload = async () => {
    setConfirmOpen(false);
    const fileInput = document.getElementById('resume-upload');
    const file = fileInput.files[0];
    if (file) {
      try {
        setUploading(true);
        await uploadResume(file, auth);
        setUploading(false);
        setUploaded(true);
        setNewResumeName('');
        setUseExistingResume(false);
        handleResumeChange({ target: { files: [file] } });
      } catch (error) {
        setUploading(false);
        setError('Error uploading resume');
        console.error('Error uploading resume:', error);
      }
    }
  };

  const cancelUpload = () => setConfirmOpen(false);

  return (
    <>
      <FormControl
        component="fieldset"
        sx={{ marginTop: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <FormLabel component="legend" sx={{ marginRight: 2 }}>Choose Resume Option</FormLabel>
        <RadioGroup
          row
          aria-label="resume"
          name="resume"
          value={useExistingResume ? 'existing' : 'new'}
          onChange={onRadioChange}
          sx={{ marginRight: 2 }}
        >
          <FormControlLabel value="existing" control={<Radio />} label="Use Existing" disabled={uploaded} />
          <FormControlLabel value="new" control={<Radio />} label="Upload New" />
        </RadioGroup>
        {useExistingResume && profile && profile.resume && (
          <>
            <Button
              color="secondary"
              variant="contained"
              sx={{ minWidth: '125px' }}
              onClick={openModal}
              aria-label="Preview Existing Resume"
            >
              Preview
            </Button>
            <ResumeModal open={modalOpen} handleClose={closeModal} resumeContent={profile.resume} />
          </>
        )}
        {!useExistingResume && (
          <>
            <input
              accept="application/pdf"
              style={{ display: 'none' }}
              id="resume-upload"
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="resume-upload">
              <Button
                color="secondary"
                variant="contained"
                sx={{ minWidth: '125px' }}
                component="span"
                disabled={uploading || uploaded}
                aria-label="Upload New Resume"
              >
                {uploading ? <CircularProgress size={24} color="inherit" /> : uploaded ? "Uploaded" : "Upload"}
              </Button>
            </label>
          </>
        )}
      </FormControl>
      {error && (
        <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
          {error}
        </Typography>
      )}
      <ConfirmDialog
        open={confirmOpen}
        onClose={cancelUpload}
        onConfirm={confirmUpload}
        title={`Uploading ${newResumeName}`}
        description={`Uploading ${newResumeName} will replace your existing resume. Do you want to proceed?`}
      />
    </>
  );
};

export default ResumeUpload;