
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { red } from '@mui/material/colors';
import { styled } from '@mui/system';

const QuoteText = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  marginTop: theme.spacing(2),
}));

const RetrySection = ({ error, handleRetry }) => {
  return (
    <Box 
      px={24} 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',  // Center content vertically
        height: '100%', 
        textAlign: 'center',  // Center text horizontally
      }}
    >
      <QuoteText variant="h3" sx={{ fontStyle: 'italic', lineHeight: 1.33}}>
        "If you don't give up, you still have a chance. Giving up is the greatest failure."
      </QuoteText>
      <Typography variant="h5" mt={3}>
        - Indra Nooyi
      </Typography>
      <Typography variant="body1" color={red[800]} mt={6} mb={2}>
          <strong>{error}</strong>
      </Typography>
      <Box width={'100%'} textAlign={'center'}>
        <Button variant="contained" color="primary" onClick={handleRetry}>
          Ask AI ro re-start the Analysis
        </Button>
      </Box>
    </Box>
  );
};

export default RetrySection;
