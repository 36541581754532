import React from 'react';
import { Card, CardContent, CardActionArea, Typography, CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/system';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { differenceInDays, parseISO } from 'date-fns';

const CardContainer = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: '0.25s',
  '&:hover': {
    backgroundColor: '#f5f5f5', // Fallback color
  },
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const RelevanceScore = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
  position: 'relative',
}));

const ScoreLabel = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  fontSize: '1.1rem', // Adjust font size as needed
  fontWeight: 600
}));

const JobCard = ({ job, onClick, selected }) => {
  const relevanceScore = job.match || 'N/A';

  // Calculate the difference in days between today and the job's posted date
  const datePosted = parseISO(job.date_posted);
  const daysSincePosted = differenceInDays(new Date(), datePosted);

  return (
    <CardContainer onClick={onClick} elevation={selected ? 10 : 1} >
      <CardActionArea sx={{ flex: 1 }}>
        <CardContent>
          <Typography variant="h6" lineHeight={1.33} gutterBottom>{job.position}</Typography>
          <Box display="flex" alignItems="center">
            <BusinessOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2">
                {job.company} - {job.team || 'N/A'}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1}>
            <CalendarMonthOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="body2">
              Posted {daysSincePosted} day{daysSincePosted !== 1 ? 's' : ''} ago
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
      <RelevanceScore my={2}>
        {relevanceScore !== 'N/A' && (
          <>
            <CircularProgress 
              variant="determinate" 
              value={100} 
              size={64} 
              sx={{ color: 'secondary.main', position: 'absolute' }} 
            />
            <CircularProgress 
              variant="determinate" 
              value={relevanceScore} 
              size={64} 
              sx={{ color: 'primary.main' }} 
            />
            <ScoreLabel variant="caption">{relevanceScore}</ScoreLabel>
          </>
        )}
      </RelevanceScore>
    </CardContainer>
  );
};

export default JobCard;
