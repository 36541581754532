export const getOverallProgress = (stage, stageProgress) => {
    const weights = {
        Scraping: 1,
        Filtering: 9,
        Analyzing: 90,
    };

    const completedWeight = {
        Scraping: 0,
        Filtering: 1,
        Analyzing: 10,
    }[stage] || 0;

    return completedWeight + (weights[stage] * stageProgress / 100);
};
  