import React from 'react';
import { Box, TextField, IconButton, Button, MenuItem, Grid, Select, FormControl, InputLabel, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DatePicker from './DatePicker';

const statuses = ['All', 'Completed', 'Failed', 'Pending', 'In Progress'];
const initialFilters = { keyword: '', location: '', status: ['Completed', "Pending", "In Progress"], dateRange: [null, null] }

const FilterSection = ({ filters, setFilters }) => {
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateRangeChange = (dateRange) => {
    setFilters((prev) => ({
      ...prev,
      dateRange,
    }));
  };

  const handleResetField = (fieldName) => {
    setFilters((prev) => ({
      ...prev,
      [fieldName]: fieldName === 'status' ? ['All'] : '',
    }));
  };

  const handleResetAll = () => {
    setFilters(initialFilters);
  };

  return (
    <Box sx={{ mt: 3, mb: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Keyword"
            name="keyword"
            value={filters.keyword}
            onChange={(e) => handleFilterChange(e)}
            fullWidth
            InputProps={{
              endAdornment: filters.keyword ? (
                <IconButton size="small" onClick={() => handleResetField('keyword')}>
                  <ClearIcon />
                </IconButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            label="Location"
            name="location"
            value={filters.location}
            onChange={(e) => handleFilterChange(e)}
            fullWidth
            InputProps={{
              endAdornment: filters.location ? (
                <IconButton size="small" onClick={() => handleResetField('location')}>
                  <ClearIcon />
                </IconButton>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              multiple
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              input={<OutlinedInput label="Status" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {statuses.map((status) => (
                <MenuItem key={status} value={status}>
                  <Checkbox checked={filters.status.indexOf(status) > -1} />
                  <ListItemText primary={status} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DatePicker
            searchDateRange={filters.dateRange}
            setSearchDateRange={handleDateRangeChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button size="large" variant="text" color="primary" onClick={handleResetAll}>
            Reset Filters
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export { 
    initialFilters,
    FilterSection
};
